import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/Navbar";
import BookAppointment from "../Components/BookAppointment";
import Reviews from "../Components/Reviews";
import Doctors from "../Components/Doctors";
import Footer from "../Components/Footer";
import colorConfig from "../Assets/config";

function NotFound() {
  const deployedURL = window.location.href;
  const location = useLocation();

  return (
    <div>
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            color: colorConfig.blue,
            fontSize: 50,
            marginTop: window.innerHeight / 2.5,
            marginBottom: window.innerHeight / 2.5,
          }}
        >
          Page Not Found
        </h1>
      </div>
      {/* <BookAppointment /> */}
      {/* <Reviews /> */}
      <Doctors />
      <Footer />
    </div>
  );
}

export default NotFound;
