import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/LegalDocs.css";
import { currentDate } from "../Scripts/default";

function LegalDocs() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="legal-section-title">
      <div className="legal-text-content">
        <h1 className="legal-siteTitle">
          <Link to="/">MMCH</Link>
        </h1>
        <p className="legal-title">General Info</p>
        <p className="legal-description">
          Welcome to MMCH, your trusted online healthcare platform. Ourmission
          is to provide accessible and personalized healthcare servicesto
          individuals seeking expert medical advice and treatment. By usingour
          platform, you agree to the terms outlined in our Privacy Policyand
          Terms of Service.
        </p>

        <p className="legal-title">Privacy Policy</p>
        <p className="legal-description">
          Your privacy is paramount to us. Our Privacy Policy outlines how
          wecollect, use, and protect your personal and medical information.
          Weensure secure data handling, and you can trust that your
          informationis treated with the utmost confidentiality.
        </p>

        <p className="legal-title">Terms of Service</p>
        <p className="legal-description">
          When using MMCH, you agree to our Terms of Service. Thisincludes
          guidelines for using our platform, interacting with doctors,and the
          responsibilities of both parties. It's essential to understandthese
          terms to ensure a smooth experience for all users.
        </p>

        <p className="legal-title">Consultations</p>
        <p className="legal-description">
          Our platform connects you with expert doctors who provide
          onlineconsultations. These consultations are not a replacement for
          in-personmedical visits but serve as a convenient option for medical
          advice,prescriptions, and guidance. It's crucial to provide accurate
          andcomplete information to receive the best possible care.
        </p>

        <p className="legal-title">Cancellations and Refunds</p>
        <p className="legal-description">
          We Refund your amount with related GST charges with 5% commission
          charges with in 4 to 5 working days.
        </p>

        <p className="legal-title">How it Works</p>
        <p className="legal-description">
          MMCH is designed to simplify healthcare access. You can choosea
          specialist, schedule an appointment, and engage in a
          virtualconsultation. Our specialists offer personalized advice and
          treatmentplans tailored to your needs. Please remember that
          emergencies requireimmediate medical attention and should be directed
          to your localmedical facility.
        </p>
      </div>

      <div className="legal-footer">
        <p>
          © 2013-{currentDate.getFullYear()} MMCH & Aura Super Blonde Hair Stylists. All rights
          reserved.
        </p>
      </div>
    </div>
  );
}

export default LegalDocs;
